.custom-background-section-career {
    position: relative;
    background-image: url('https://res.cloudinary.com/dryskp7hv/image/upload/v1735435527/concept-espace-travail-vue-dessus-tableau_23-2148236806_lslthq.jpg');
    /* Image de fond personnalisée */
    background-size: cover;
    padding-top: 225px;
    padding-bottom: 110px;
    background-position: center;
    background-size: cover;
    background-position: center;
}

.custom-background-section-career::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    /* Permet de ne pas bloquer les clics sur le contenu */
    background-color: rgba(241, 184, 28, 0.2);
    /* Couche d'opacité */
    z-index: 1;
}

.custom-background-section-career .custom-content {
    position: relative;
    z-index: 2;
    color: white;
}