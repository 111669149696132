.custom-background-section-profile {
  position: relative;
  background-image: url('https://res.cloudinary.com/dryskp7hv/image/upload/v1732302738/240_F_665479153_UZ1H6ERAXeNOVlV0TGi1aJYHEyQThH6G_jdjurs.jpg'); /* Image de fond personnalisée */
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 50px;
  background-position: center;
  background-size: cover;
  background-position: center;
}

.custom-background-section-profile::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Permet de ne pas bloquer les clics sur le contenu */
  background-color: rgba(80,221,187, 0.2); /* Couche d'opacité */
  z-index: 1;
}

.custom-background-section-profile .custom-content {
  position: relative;
  z-index: 2;
  color: white;
}

.hide-buttons .hide-in-pdf {
  display: none !important;
}
