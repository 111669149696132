.custom-background-section-job {
    position: relative;
    background-image: url('https://res.cloudinary.com/dryskp7hv/image/upload/v1727882398/7609bf10-3263-4a4e-b6d2-62dd7a88773f.png'); /* Image de fond personnalisée */
    background-size: cover;
    padding-top: 200px;
    padding-bottom: 50px;
    background-position: center;
    background-size: cover;
    background-position: center;
  }
  
  .custom-background-section-job::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Permet de ne pas bloquer les clics sur le contenu */
    background-color: rgba(232,198,114, 0.3); /* Couche d'opacité */
    z-index: 1;
  }
  
  .custom-background-section-job .custom-content {
    position: relative;
    z-index: 2;
    color: white;
  }
    /* Job description Css */
    .offer-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* Limite à 2 lignes */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      max-width: 80%;
      /* Empêche le dépassement */
    }