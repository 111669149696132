

.card-header {
    position: relative; 
    height: 150px; /* Ajustez la hauteur selon vos besoins */
    width: 100%;
    overflow: hidden;
  }
  
  .card-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .custom-background-section-training {
    position: relative;
    background-image: url('https://res.cloudinary.com/dryskp7hv/image/upload/v1728229010/7f91d171-5f40-4ae8-8e57-42b33313bc00.png'); /* Image de fond personnalisée */
    background-size: cover;
    padding-top: 200px;
    padding-bottom: 50px;
    background-position: center;
    background-size: cover;
    background-position: center;
  }
  
  .custom-background-section-training::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Permet de ne pas bloquer les clics sur le contenu */
    background-color: rgba(49,171,223, 0.5); /* Couche d'opacité */
    z-index: 1;
  }
  
  .custom-background-section-training .custom-content {
    position: relative;
    z-index: 2;
    color: white;
  }
  