.custom-background-section-company {
    position: relative;
    background-image: url('https://res.cloudinary.com/dryskp7hv/image/upload/v1732314769/designers-industriels-travaillant-modele-3d_23-2149370166_zpy9or.avif'); /* Image de fond personnalisée */
    background-size: cover;
    padding-top: 200px;
    padding-bottom: 50px;
    background-position: center;
    background-size: cover;
    background-position: center;
  }
  
  .custom-background-section-company::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Permet de ne pas bloquer les clics sur le contenu */
    background-color: rgba(240, 100, 58, 0.5);
      /* Couche d'opacité */          /* Couche d'opacité */
    z-index: 1;
  }
  
  .custom-background-section-company .custom-content {
    position: relative;
    z-index: 2;
    color: white;
  }