.bg-cyan {
    background-color: #FAC96F !important;
    /* Couleur bleue */
}

.bg-red {
    background-color: #31ABDF !important;
    /* Couleur rouge */
}

.text-white {
    color: #ffffff !important;
    /* Texte blanc pour contraster */
}