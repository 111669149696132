.custom-background-section-academy {
    position: relative;
    background-image: url('https://res.cloudinary.com/dryskp7hv/image/upload/v1682611382/Eburnie%20hub/img/image00014_puigaq.jpg');
    /* Image de fond personnalisée */
    background-size: cover;
    padding-top: 200px;
    padding-bottom: 50px;
    background-position: center;
    background-size: cover;
    background-position: center;
}

.custom-background-section-academy::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    /* Permet de ne pas bloquer les clics sur le contenu */
    background-color: rgba(232, 198, 114, 0.3);
    /* Couche d'opacité */
    z-index: 1;
}

.custom-background-section-academy .custom-content {
    position: relative;
    z-index: 2;
    color: white;
}