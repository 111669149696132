.blog-image {
    max-width: 100%;
    /* Ajuste la largeur maximale de l'image */
    height: auto;
    /* Conserve les proportions de l'image */
}


/* BlogGrid.css */

/* Style pour le conteneur de la grille du blog */
.blog-grid {
    margin-top: 20px;
}

/* Style pour la colonne de contenu */
.blog-post-container {
    background: #ffff;
    /* Fond clair pour une meilleure lisibilité */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style pour la colonne des modules */
.sidebar-container {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style pour les cartes ou boutons des modules */
.sidebar-container .accordion-item {
    border: none;
}

.sidebar-container .accordion-button {
    background: #e9ecef;
    /* Fond pour les boutons des modules */
    color: #495057;
    border-radius: 8px;
    transition: background-color 0.2s ease;
}

.sidebar-container .accordion-button:hover {
    background: #dee2e6;
    /* Fond au survol */
}

/* Style pour les liens des cours */
.blog-category a {
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    color: #fac96f;
    /* Couleur du texte des cours */
    text-decoration: none;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.blog-category a:hover {
    background-color: #fac96f;
    color: #ffffff;
}

/* Style pour le texte du cours */
.blog-post-container h5 {
    font-size: 1.5rem;
    color: #343a40;
}

.blog-post-container h6 {
    font-size: 1.25rem;
    color: #495057;
}

.accordion-button.active {
    background-color: #14b8a6;
    color: white;
}

.accordion-body .active {
    font-weight: bold;
    color: #14b8a6;
}

