/* jobVacancySlider.css */
.slider-container {
    padding: 20px 0;
    height: 400px;
}

.similar-offers-slider {
    margin-bottom: 30px;
}

.slider-card-wrapper {
    padding: 8px;
    height: 100%;
}

.primary-link {
    text-decoration: none;
    color: inherit;
    display: block;
    height: 100%;
}

.blog-masonry-box {
    transition: all 0.3s ease;
    height: 100%;
}

.blog-masonry-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08) !important;
    border-left: 3px solid #007bff !important;
}

.disabled-link {
    pointer-events: none;
    /* Désactive le clic */
    opacity: 0.5;
    /* Rend le lien visuellement moins visible */
    cursor: not-allowed;
    /* Curseur interdit */
}

.job-detail-desc {
    max-height: 100px;
    min-height: 100px;
    overflow: hidden;
    font-size: 14px;
    color: #666;
}

.avatar-xs {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border: 1px solid #eee;
}

/* Personnalisation du slider */
.slick-prev,
.slick-next {
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.slick-prev:before,
.slick-next:before {
    color: #007bff;
    opacity: 0.8;
}

.slick-prev {
    left: -15px;
}

.slick-next {
    right: -15px;
}

.slick-dots li button:before {
    font-size: 10px;
    color: #ccc;
}

.slick-dots li.slick-active button:before {
    color: #007bff;
}

/* Styles responsifs */
@media (max-width: 768px) {
    .slick-prev,
    .slick-next {
        display: none !important;
    }

    .slider-card-wrapper {
        padding: 5px;
    }
}

/* Animation pour les badges */
.badge {
    transition: all 0.2s ease;
}

.badge:hover {
    transform: scale(1.05);
}