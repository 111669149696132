/* BlogSwiper.css */
.card {
    position: relative;
    border: 1px solid #ddd; /* Bordure autour de la carte */
    border-radius: 8px; /* Coins arrondis de la carte */
    overflow: hidden;
  }
  
  .full-width-image {
    width: 100%; /* Remplit le conteneur en largeur */
    height: auto; /* Conserve les proportions de l'image */
    object-fit: cover; /* Recouvre le conteneur sans déformation */
    border: 2px solid #14B8A6; /* Bordure autour de l'image */
    border-radius: 4px; /* Coins arrondis pour l'image */
  }
  
  .border-container {
    padding: 0;
    margin: 0;
  }
  