.multi-step-form-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .progress-bar {
    width: 100%;
    height: 5px;
    background-color: #50D3BB;
    border-radius: 2.5px;
    transition: width 0.3s ease;
    position: relative;
    top: 10px;
  }
  
  .progress-steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding: 0 10px;
  }
  
  .progress-step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
  }
  
  .progress-step {
    width: 20px;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .progress-step.completed {
    background-color: #50D3BB;
  }
  
  .step-label {
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
  